import '../../../shared/components/general/nova-side-panel/nova-side-panel.js';
import '../../../shared/components/general/nova-side-panel-item/nova-side-panel-item.js';
import '../../components/settings-finance/settings-finance.js';
import '../../components/settings-user-manangement/settings-user-manangement.js';

import { css, html, LitElement } from 'lit';
import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';

import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../shared/mixins/nova-nav/nova-nav.js';

export default class ViewSettings extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(NovaNavMixin(LitElement))))) {
  static get properties() {
    return {
      params: { type: Object },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.skeleton = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this.skeleton = false;
  }

  render() {
    // TODO Update permissions
    if (!this.session?.tenant?.hasTag('plgTenant')) this.navigateWithoutHistory('/403');

    return html`
      <nova-side-panel
        .skeleton="${this.skeleton}"
        .selectedPanel="${this.params?.panel || 'details'}"
      >
        <nova-side-panel-item
          panel="details"
          label=${this.localize('view-settings.details')}
          icon="tier1:home"
          >
            <h1 class="d2l-heading-1">${this.localize('view-settings.details')}</h1>
        </nova-side-panel-item>
        <nova-side-panel-item
          panel="user-management"
          label=${this.localize('view-settings.user-management')}
          icon="tier1:lock-locked"
          >
            <h1 class="d2l-heading-1">${this.localize('view-settings.user-management')}</h1>
            <settings-user-manangement></settings-user-manangement>
        </nova-side-panel-item>
        <nova-side-panel-item
          panel="finance"
          label=${this.localize('view-settings.finance')}
          icon="tier1:lor"
          >
            <h1 class="d2l-heading-1">${this.localize('view-settings.finance')}</h1>
            <settings-finance></settings-finance>
        </nova-side-panel-item>
        <nova-side-panel-item
          panel="catalog-preferences"
          label=${this.localize('view-settings.catalog-preferences')}
          icon="tier1:checklist"
          >
            <h1 class="d2l-heading-1">${this.localize('view-settings.catalog-preferences')}</h1>
        </nova-side-panel-item>
      </nova-side-panel>
    `;
  }
}

window.customElements.define('view-settings', ViewSettings);
