import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';

import '../../../admin/components/users-table/users-table.js';

class SettingsUserManangement extends LocalizeNova(SkeletonMixin(RequesterMixin(LitElement))) {
  static get properties() {
    return {
      allRoles: { type: Array },
    };
  }

  static get styles() {
    return css`
      /* Add styles here */
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
    this.skeleton = true;
    this.tenantId = this.session._tenant.id;
    this.allRoles = await this.client.getRolesByTenantId(this.tenantId);
    this.skeleton = false;
  }
  render() {
    return html`
      <users-table class="d2l-skeletize" .allRoles="${this.allRoles}"></users-table>
    `;
  }
}

window.customElements.define('settings-user-manangement', SettingsUserManangement);

